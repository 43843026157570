// NOTIFIER: MATERIAL THEME
//
// This material theme tries its best to look as material-design'ish as possible - round edges, shadows, and small animations.
// And, of course, thanks to #Google for creating and sharing such an awesome design language!
// I highly encourage everyone to read into the Material Design specs: <https://material.google.com/>

$notifier-shadow-color: rgba(0, 0, 0, .2) !default;


.notifier__notification {
	border-left: 4px solid var(--color-surface-container-high); // default color
	&--info {
		border-left-color: var(--color-completed);
	}
	&--success {
		border-left-color: var(--color-running);
	}
	&--warning {
		border-left-color: var(--color-warning);
	}
	&--error {
		border-left-color: var(--color-failed);
	}
}


.notifier__notification--material {
	border-radius: 3px;
	box-shadow: 0 1px 3px $notifier-shadow-color;
	cursor: default; // Default cursor, even when hovering over text
	padding: 12px 0;

	.notifier__notification {
		&-message {
			display: inline-block;
			margin: { // Reset paragraph default styles
				top: 4px;
				bottom: 0;
			}

      .message {
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
      }

			.user-action {
				display: inline-block;
				font-weight: 500;
				margin-top: 10px;
				margin-right: 12px;
				cursor: pointer;
			}
		}

	}

}
