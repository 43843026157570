$icomoon-font-family: "trains" !default;
$icomoon-font-path: "fonts" !default;

$al-ico-enterprise-rocket: unquote('"\\ea19"');
$al-ico-no-data-table: unquote('"\\ea18"');
$al-ico-palette: unquote('"\\e900"');
$al-ico-log: unquote('"\\ea16"');
$al-ico-debug-samples: unquote('"\\ea15"');
$al-ico-artifacts: unquote('"\\ea14"');
$al-ico-close-circle: unquote('"\\ea13"');
$al-ico-bullhorn: unquote('"\\ea12"');
$al-ico-view-horizontal: unquote('"\\ea11"');
$al-ico-model-endpoints: unquote('"\\ea08"');
$al-ico-vm: unquote('"\\ea0c"');
$al-ico-retry: unquote('"\\ea07"');
$al-ico-link-off: unquote('"\\ea05"');
$al-ico-policy: unquote('"\\e9d5"');
$al-ico-info-group: unquote('"\\e944"');
$al-ico-advanced-filters: unquote('"\\e911"');
$al-ico-triggers-scheduled: unquote('"\\ea06"');
$al-ico-queue: unquote('"\\ea01"');
$al-ico-link-plus: unquote('"\\ea02"');
$al-ico-drag-vertical: unquote('"\\ea03"');
$al-ico-drag-horizontal: unquote('"\\ea04"');
$al-ico-admin-support: unquote('"\\ea00"');
$al-ico-scatter-view: unquote('"\\e9ff"');
$al-ico-schedulers: unquote('"\\e9fb"');
$al-ico-triggers: unquote('"\\e9fc"');
$al-ico-autoscalers: unquote('"\\e9fd"');
$al-ico-automation: unquote('"\\e9fe"');
$al-ico-charts-view: unquote('"\\e9f9"');
$al-ico-compact-view: unquote('"\\e9fa"');
$al-ico-tune: unquote('"\\e9f8"');
$al-ico-equal-outline: unquote('"\\e9f7"');
$al-ico-maximize: unquote('"\\e9f6"');
$al-ico-gpu-card: unquote('"\\e9f4"');
$al-ico-legend: unquote('"\\e9f3"');
$al-ico-model-filled: unquote('"\\e9f2"');
$al-ico-type-report: unquote('"\\e9f1"');
$al-ico-info-circle-outline: unquote('"\\e9f0"');
$al-ico-ghost: unquote('"\\e9ef"');
$al-ico-flat-view: unquote('"\\e9ee"');
$al-ico-camera: unquote('"\\e9ed"');
$al-ico-markdown: unquote('"\\e9ec"');
$al-ico-hor-expand: unquote('"\\e9ea"');
$al-ico-hor-minimize: unquote('"\\e9eb"');
$al-ico-pdf: unquote('"\\e9e9"');
$al-ico-reports: unquote('"\\e9e8"');
$al-ico-gpu: unquote('"\\e9e7"');
$al-ico-project-path: unquote('"\\e9e6"');
$al-ico-tree-view: unquote('"\\e9e5"');
$al-ico-sort-asc: unquote('"\\e9e3"');
$al-ico-sort-desc: unquote('"\\e9e4"');
$al-ico-grid-view: unquote('"\\e9e0"');
$al-ico-connect: unquote('"\\e9de"');
$al-ico-disconnect: unquote('"\\e9da"');
$al-ico-trash-all: unquote('"\\e9d9"');
$al-ico-drag: unquote('"\\e9d8"');
$al-ico-py: unquote('"\\e9d7"');
$al-ico-file: unquote('"\\e9d3"');
$al-ico-txt: unquote('"\\e9d6"');
$al-ico-pkl: unquote('"\\e9cd"');
$al-ico-image: unquote('"\\e958"');
$al-ico-zip: unquote('"\\e9ce"');
$al-ico-code-file: unquote('"\\e9a4"');
$al-ico-audio: unquote('"\\e9d0"');
$al-ico-upload: unquote('"\\e9cc"');
$al-ico-min-panel: unquote('"\\e9ca"');
$al-ico-max-panel: unquote('"\\e9cb"');
$al-ico-datasets: unquote('"\\e90b"');
$al-ico-t-logo-b: unquote('"\\e908"');
$al-ico-bars-menu: unquote('"\\e933"');
$al-ico-home: unquote('"\\e909"');
$al-ico-projects: unquote('"\\e90a"');
$al-ico-queues: unquote('"\\e90c"');
$al-ico-annotator: unquote('"\\e90d"');
$al-ico-account: unquote('"\\e998"');
$al-ico-compare: unquote('"\\e93f"');
$al-ico-archive: unquote('"\\e92c"');
$al-ico-cloud-head: unquote('"\\e9a6"');
$al-ico-how-to: unquote('"\\e997"');
$al-ico-compare-c: unquote('"\\e93a"');
$al-ico-published-title: unquote('"\\e9d2"');
$al-ico-publish: unquote('"\\e9cf"');
$al-ico-unpublish: unquote('"\\ea0f"');
$al-ico-published: unquote('"\\e9d1"');
$al-ico-info: unquote('"\\e99b"');
$al-ico-warn: unquote('"\\ea10"');
$al-ico-settings: unquote('"\\e9e2"');
$al-ico-trash: unquote('"\\ea0b"');
$al-ico-arrow-from-right: unquote('"\\e92e"');
$al-ico-arrow-from-left: unquote('"\\e92d"');
$al-ico-arrow-to-bottom: unquote('"\\e92f"');
$al-ico-arrow-to-top: unquote('"\\e930"');
$al-ico-training: unquote('"\\e90e"');
$al-ico-test: unquote('"\\ea09"');
$al-ico-testing: unquote('"\\ea0a"');
$al-ico-type-training: unquote('"\\ea33"');
$al-ico-type-testing: unquote('"\\ea32"');
$al-ico-type-data-processing: unquote('"\\ea2c"');
$al-ico-type-qc: unquote('"\\ea30"');
$al-ico-type-service: unquote('"\\ea31"');
$al-ico-type-optimizer: unquote('"\\ea2f"');
$al-ico-type-monitor: unquote('"\\ea2e"');
$al-ico-type-inference: unquote('"\\ea2d"');
$al-ico-type-application: unquote('"\\ea29"');
$al-ico-type-controller: unquote('"\\ea2a"');
$al-ico-type-custom: unquote('"\\ea2b"');
$al-ico-how-to1: unquote('"\\e90f"');
$al-ico-model: unquote('"\\e910"');
$al-ico-dialog-x: unquote('"\\e980"');
$al-ico-temp-image: unquote('"\\e912"');
$al-ico-temp-list-alt: unquote('"\\e913"');
$al-ico-dog: unquote('"\\e914"');
$al-ico-alert-purple: unquote('"\\e927"');
$al-ico-back: unquote('"\\e931"');
$al-ico-cat: unquote('"\\e936"');
$al-ico-clone: unquote('"\\e937"');
$al-ico-completed: unquote('"\\e940"');
$al-ico-data-audit: unquote('"\\e943"');
$al-ico-download-frames: unquote('"\\e982"');
$al-ico-dropdown-arrow: unquote('"\\e984"');
$al-ico-ellipse-icon: unquote('"\\e986"');
$al-ico-filter: unquote('"\\e992"');
$al-ico-fit: unquote('"\\e995"');
$al-ico-id: unquote('"\\e999"');
$al-ico-info-max: unquote('"\\e99c"');
$al-ico-info-min: unquote('"\\e99d"');
$al-ico-keypoint: unquote('"\\e99f"');
$al-ico-list-view: unquote('"\\e9a5"');
$al-ico-minus: unquote('"\\e9a7"');
$al-ico-previous: unquote('"\\e9bb"');
$al-ico-next: unquote('"\\e9af"');
$al-ico-plus: unquote('"\\e9b8"');
$al-ico-polygon: unquote('"\\e9b9"');
$al-ico-pytorch-icon: unquote('"\\e9d4"');
$al-ico-rectangle: unquote('"\\e9db"');
$al-ico-running: unquote('"\\e9df"');
$al-ico-setup: unquote('"\\ea0d"');
$al-ico-undo: unquote('"\\ea0e"');
$al-ico-zoom-1-to-1: unquote('"\\ea25"');
$al-ico-zoom-in: unquote('"\\ea26"');
$al-ico-zoom-out: unquote('"\\ea27"');
$al-ico-search: unquote('"\\e9e1"');
$al-ico-zoom-to-fit: unquote('"\\ea28"');
$al-ico-reset: unquote('"\\e9dd"');
$al-ico-import: unquote('"\\e99e"');
$al-ico-export: unquote('"\\e9b6"');
$al-ico-between: unquote('"\\e934"');
$al-ico-next-batch: unquote('"\\e9b0"');
$al-ico-prev-batch: unquote('"\\e9ba"');
$al-ico-back-to-top: unquote('"\\e932"');
$al-ico-redo: unquote('"\\e9dc"');
$al-ico-download: unquote('"\\e981"');
$al-ico-edit: unquote('"\\e983"');
$al-ico-pending: unquote('"\\e9b7"');
$al-ico-sqr-empty: unquote('"\\e925"');
$al-ico-success: unquote('"\\e9f5"');
$al-ico-alert-outline: unquote('"\\e926"');
$al-ico-github: unquote('"\\e915"');
$al-ico-description: unquote('"\\e916"');
$al-ico-line-expand: unquote('"\\e901"');
$al-ico-user-logout: unquote('"\\e902"');
$al-ico-documentation: unquote('"\\e919"');
$al-ico-frame-rule: unquote('"\\e91a"');
$al-ico-source-rule: unquote('"\\e91b"');
$al-ico-get-link-dialog: unquote('"\\e91d"');
$al-ico-iteration: unquote('"\\e91e"');
$al-ico-mapping: unquote('"\\e91f"');
$al-ico-labels: unquote('"\\e920"');
$al-ico-augmentation: unquote('"\\e921"');
$al-ico-filter-outlined: unquote('"\\e922"');
$al-ico-input-data: unquote('"\\e923"');
$al-ico-help-outlined: unquote('"\\e924"');
$al-ico-email: unquote('"\\e928"');
$al-ico-tips: unquote('"\\e929"');
$al-ico-caret-right: unquote('"\\e92a"');
$al-ico-currently-active: unquote('"\\e92b"');
$al-ico-complete: unquote('"\\e92b"');
$al-ico-access-key: unquote('"\\e935"');
$al-ico-alert: unquote('"\\e9b3"');
$al-ico-users: unquote('"\\e91c"');
$al-ico-exit-archive: unquote('"\\e938"');
$al-ico-calendar: unquote('"\\e939"');
$al-ico-time: unquote('"\\e93b"');
$al-ico-add: unquote('"\\e93c"');
$al-ico-time-colon: unquote('"\\e93d"');
$al-ico-regex: unquote('"\\e93e"');
$al-ico-filter-on-path1: unquote('"\\e941"');
$al-ico-filter-on-path2: unquote('"\\e942"');
$al-ico-filter-off: unquote('"\\e945"');
$al-ico-sort-off: unquote('"\\e946"');
$al-ico-sort-on-down-path1: unquote('"\\e947"');
$al-ico-sort-on-down-path2: unquote('"\\e948"');
$al-ico-sort-on-up-path1: unquote('"\\e949"');
$al-ico-sort-on-up-path2: unquote('"\\e94a"');
$al-ico-arrow-left: unquote('"\\e94b"');
$al-ico-arrow-right: unquote('"\\e94c"');
$al-ico-step-backward: unquote('"\\e94d"');
$al-ico-step-forward: unquote('"\\e94e"');
$al-ico-backward: unquote('"\\e94f"');
$al-ico-forward: unquote('"\\e950"');
$al-ico-copy-to-clipboard: unquote('"\\e951"');
$al-ico-card-example: unquote('"\\e952"');
$al-ico-bold: unquote('"\\e953"');
$al-ico-italic: unquote('"\\e954"');
$al-ico-heading: unquote('"\\e955"');
$al-ico-quote: unquote('"\\e956"');
$al-ico-link: unquote('"\\e957"');
$al-ico-code: unquote('"\\e959"');
$al-ico-list-bulleted: unquote('"\\e95a"');
$al-ico-list-numbered: unquote('"\\e95b"');
$al-ico-me: unquote('"\\e95c"');
$al-ico-team: unquote('"\\e95d"');
$al-ico-task-desc: unquote('"\\e95e"');
$al-ico-dots: unquote('"\\e95f"');
$al-ico-move-to: unquote('"\\e960"');
$al-ico-abort: unquote('"\\e961"');
$al-ico-extend: unquote('"\\e962"');
$al-ico-reset-exp: unquote('"\\e963"');
$al-ico-tag: unquote('"\\e964"');
$al-ico-shared-item: unquote('"\\e965"');
$al-ico-restore: unquote('"\\e966"');
$al-ico-workers: unquote('"\\e967"');
$al-ico-dots-v-menu: unquote('"\\e96a"');
$al-ico-d-menu-down: unquote('"\\e96b"');
$al-ico-d-menu-up: unquote('"\\e96c"');
$al-ico-slash: unquote('"\\e96d"');
$al-ico-info-circle: unquote('"\\e96e"');
$al-ico-annotate: unquote('"\\e96f"');
$al-ico-task-desc-outline: unquote('"\\e970"');
$al-ico-manage-queue: unquote('"\\e968"');
$al-ico-enqueue: unquote('"\\e969"');
$al-ico-dequeue: unquote('"\\e971"');
$al-ico-applications: unquote('"\\e972"');
$al-ico-ico-chevron-up: unquote('"\\e973"');
$al-ico-ico-chevron-down: unquote('"\\e974"');
$al-ico-no-data-graph: unquote('"\\e975"');
$al-ico-no-scatter-graph: unquote('"\\e976"');
$al-ico-auto-refresh-play-path1: unquote('"\\e977"');
$al-ico-auto-refresh-play-path2: unquote('"\\e978"');
$al-ico-auto-refresh-pause-path1: unquote('"\\e979"');
$al-ico-auto-refresh-pause-path2: unquote('"\\e97a"');
$al-ico-sqr-ok: unquote('"\\e97b"');
$al-ico-sqr-cancel: unquote('"\\e97c"');
$al-ico-queue-lg: unquote('"\\e97d"');
$al-ico-started-lg: unquote('"\\e97e"');
$al-ico-admin: unquote('"\\e97f"');
$al-ico-projects-outlined: unquote('"\\e985"');
$al-ico-datasets-outlined: unquote('"\\e987"');
$al-ico-hide: unquote('"\\e988"');
$al-ico-show: unquote('"\\e989"');
$al-ico-metadata: unquote('"\\e98a"');
$al-ico-filter-reset-path1: unquote('"\\e98b"');
$al-ico-filter-reset-path2: unquote('"\\e98c"');
$al-ico-version-label: unquote('"\\e98d"');
$al-ico-plugin: unquote('"\\e98e"');
$al-ico-abort-all: unquote('"\\e98f"');
$al-ico-refresh: unquote('"\\e990"');
$al-ico-rocket: unquote('"\\e991"');
$al-ico-logout: unquote('"\\e993"');
$al-ico-settings-alert-path1: unquote('"\\e994"');
$al-ico-settings-alert-path2: unquote('"\\e996"');
$al-ico-platform: unquote('"\\e99a"');
$al-ico-creditcard: unquote('"\\e9a0"');
$al-ico-star: unquote('"\\e9a1"');
$al-ico-email-check: unquote('"\\e9a2"');
$al-ico-slack: unquote('"\\e9a3"');
$al-ico-youtube: unquote('"\\e9a8"');
$al-ico-lock: unquote('"\\e9a9"');
$al-ico-lock-open: unquote('"\\e9aa"');
$al-ico-no-code: unquote('"\\e9ab"');
$al-ico-calendar-checked: unquote('"\\e9ad"');
$al-ico-no-source: unquote('"\\e9ae"');
$al-ico-arrow-up: unquote('"\\e9b1"');
$al-ico-arrow-down: unquote('"\\e9b2"');
$al-ico-error-circle: unquote('"\\e9b4"');
$al-ico-pipelines: unquote('"\\e9b5"');
$al-ico-console: unquote('"\\e9bc"');
$al-ico-link-arrow: unquote('"\\e9bf"');
$al-ico-broken-file: unquote('"\\e9c0"');
$al-ico-run: unquote('"\\e9c1"');
$al-ico-table-view: unquote('"\\e9c2"');
$al-ico-experiment-view: unquote('"\\e9c3"');
$al-ico-code-square: unquote('"\\e9c4"');
$al-ico-video: unquote('"\\e9c5"');
$al-ico-less-than: unquote('"\\e9c6"');
$al-ico-greater-than: unquote('"\\e9c7"');
$al-ico-eye-outline: unquote('"\\e9c8"');
$al-ico-csv: unquote('"\\e9c9"');
$al-ico-status-draft: unquote('"\\e903"');
$al-ico-status-published: unquote('"\\e906"');
$al-ico-status-aborted-sec: unquote('"\\e918"');
$al-ico-status-pending: unquote('"\\e904"');
$al-ico-status-skipped: unquote('"\\e9bd"');
$al-ico-status-cached: unquote('"\\e9be"');
$al-ico-status-executed: unquote('"\\e9ac"');
$al-ico-status-running: unquote('"\\e905"');
$al-ico-status-failed: unquote('"\\e907"');
$al-ico-status-aborted: unquote('"\\e917"');
$al-ico-status-completed: unquote('"\\ea17"');
