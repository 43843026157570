@use '@angular/material' as mat;

:root {
  // Row header
  --row-header-color: var(--color-surface-container-high);
  --row-header-hover-color: var(--color-surface-container-highest);
  // --

  // Default
  --row-default-text-color: var(--color-on-primary-container);
  --row-default-color: var(--color-surface-container-lowest);
  --row-default-hover-color: color-mix(in srgb, var(--color-surface-container-lowest), var(--color-on-surface) 4%);
  // --

  // Selection
  --row-selection-text-color: var(--color-on-secondary-container);
  --row-selection-color: var(--color-tint-5);
  --row-selection-hover-color: color-mix(in srgb, var(--color-secondary-container), var(--color-surface) 50%);
  // --

  // Selected
  --row-selected-text-color: var(--color-on-secondary);
  --row-selected-color: var(--color-primary);
  --row-selected-hover-color: color-mix(in srgb, var(--color-primary), var(--color-on-surface) 20%);
  // --

  // Border
  --row-border-color: var(--color-surface-container-high);
}

.select-all-checkbox .mat-mdc-checkbox-touch-target {
  width: 24px;
}

@mixin table($theme) {
  @if mat.get-theme-type($theme) == dark {
    // Row header
    --row-header-color: var(--color-surface-container-high);

    // Default
    --row-default-color: color-mix(in srgb, var(--color-surface-container), var(--color-on-surface) 5%);
    --row-default-hover-color: color-mix(in srgb, var(--color-surface-container), var(--color-on-surface) 10%);

    // Selection
    --row-selection-hover-color: color-mix(in srgb, var(--color-secondary-container), var(--color-on-surface) 8%);

    // Selected
    --row-selected-hover-color: color-mix(in srgb, var(--color-primary), var(--color-on-surface) 40%);

    // Border
    --row-border-color: var(--color-surface-container-lowest);
  }
}
