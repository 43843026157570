:root {
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    min-height: 32px;
    min-width: 32px;
    border-style: solid;
    border-color: transparent;
    border-width: 4px;
    border-radius: 14px;
    background-clip: padding-box;
    background-color: color-mix(in srgb, var(--color-surface-container-highest), var(--color-on-surface) 20%);
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent
  }
}

// FF
@supports not selector(::-webkit-scrollbar) {
  :root {
    scrollbar-color: color-mix(in srgb, var(--color-surface-container-highest), var(--color-on-surface) 20%) transparent;
    scrollbar-width: thin;
  }
}
