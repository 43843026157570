@import "variables";

* {
  outline: none !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-family-base;
  font-size: 14px;
  overflow: hidden;
}


a {
  text-decoration: none;
}
