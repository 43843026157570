@import "colors";
@import "buttons";
@import "tabs";
@import "menus";
@import "form-fields";
@import "dialogs";
@import "scrollbar";
@import "expansion-panel";
@import "sidenav";
@import "cards";
@import "table";
@import "tooltip";
@import "slide-toggle";
@import "icons";
@import "links";
@import "divider";
@import "ace";
@import "slider";
@import "angular-split";
@import "markdown";

body {
  color: var(--color-on-surface-variant);
}

@mixin components($theme) {
  @include generate-colors($theme);
  @include table($theme);
  @include buttons($theme);
  @include tabs($theme);
  @include form-fields($theme);
  @include cards($theme);
  @include dialogs($theme);
  @include ace-style($theme);
}
